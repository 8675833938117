<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="editedItem.title.value"
                                class="purple-input"
                                :label="$t('admin.posts.titleAtt')"
                                :error-messages="editedItem.title.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.title.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="3"
                            sm="6"
                        >
                            <v-text-field
                                v-model="editedItem.order.value"
                                class="purple-input"
                                :label="$t('admin.posts.order')"
                                :error-messages="editedItem.order.error"
                                :rules="[rules.required, rules.validNumber]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.order.error = ''"
                            />
                        </v-col>

                        <v-spacer />

                        <v-col
                            cols="12"
                            md="5"
                            lg="3"
                            sm="6"
                        >
                            <v-select
                                v-model="editedItem.company.value"
                                :error-messages="editedItem.company.error"
                                :items="companies"
                                attach
                                :label="$t('admin.terms.company')"
                                item-text="attributes.name"
                                item-value="id"
                                :disabled="isOnlyView"
                                clearable
                                @change="editedItem.company.error = ''"
                            />
                        </v-col>

                        <v-spacer />

                        <v-col
                            cols="12"
                            md="5"
                            lg="3"
                            sm="6"
                        >
                            <v-select
                                v-model="editedItem.term_category_id.value"
                                :error-messages="editedItem.term_category_id.error"
                                :items="termsCategories"
                                attach
                                :label="$t('admin.terms.term_category_id')"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.requiredAtLeastOne]"
                                :disabled="isOnlyView"
                                @change="editedItem.term_category_id.error = ''"
                            />
                        </v-col>

                        <v-col
                            v-if="isOnlyView"
                            class="py-5"
                            cols="12"
                        >
                            <label>{{ $t('admin.posts.pageBody') }}</label>
                            <div
                                class="ql-editor"
                                v-html="editedItem.body.value"
                            />
                        </v-col>

                        <v-col
                            v-else
                            cols="12"
                        >
                            <div
                                class="text-h6 d-flex justify-space-between"
                            >
                                <!--                                <span>{{ $t(`admin.posts.${editorVisual ? 'editorVisual' : 'editorCode'}`) }}</span>-->
                                <v-switch
                                    v-model="editorVisual"
                                    inset
                                    :label="$t('admin.posts.editorVisual')"
                                />

                                <v-btn
                                    class="mx-2 pb-0 mb-0 mt-4"
                                    fab
                                    dark
                                    color="primary"
                                    small
                                    @click="restoreBody"
                                >
                                    <v-icon dark>
                                        mdi-undo
                                    </v-icon>
                                </v-btn>
                            </div>

                            <vue-editor
                                v-if="editorVisual"
                                v-model="editedItem.body.value"
                                :editor-options="editorSettings"
                            />
                            <v-textarea
                                v-else
                                name="input-7-1"
                                :value="editedItem.body.value"
                                outlined
                                @change="editorCodeChanged($event)"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer />

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${ isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import CompaniesAPIService from "@/services/CompaniesAPIService";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
    components: { VueEditor },
    data: function () {
        return {
            urlMain: '/legal/terms',
            rules: {
                ...rules
            },
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,

            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {}

                }
            },
            editorVisual: true,
            termsCategories: [],
            companies: []
        }
    },
    computed: {
        formTitle() {
            if (this.item) { return this.isOnlyView ? this.$t('admin.terms.view') : this.$t('admin.terms.edit') } else return this.$t('admin.terms.new')
        },
        ...mapGetters('cache', ['getImages'])
        // ...mapGetters(['role', 'cities'])
    },
    created() {
        // console.log(this.item);
        /* console.log('this.$route.params')
        console.log(this.$route.params) */
    },
    /* watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    }, */
    mounted() {
        this.item = this.$route.params.item ?? null;
        this.termsCategories = JSON.parse(this.$route.params.termsCategories) ?? [];

        this.fetchCompanies();

        this.addTooltipToEditor();
    },
    methods: {
        getEditedItem(itemP) {
            const item = itemP ?? this.$route.params.item ?? null;

            var empty = !item

            return {
                id: empty ? '' : item.id,
                title: {
                    value: empty ? '' : item.title,
                    error: ''
                },
                body: {
                    value: empty ? '' : item.body,
                    error: ''
                },
                order: {
                    value: empty ? '' : item.order,
                    error: ''
                },
                term_category_id: {
                    value: (empty || !item.term_category_id) ? '' : parseInt(item.term_category_id),
                    error: ''
                },
                company: {
                    value: (empty || !item.company_id) ? '' : item.company_id + '',
                    error: ''
                }
            }
        },
        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("title", this.editedItem.title.value);
            formData.append("body", this.editedItem.body.value ?? '');
            formData.append("order", this.editedItem.order.value);
            formData.append("term_category_id", this.editedItem.term_category_id.value);

            if (this.editedItem.company.value) {
                formData.append("company", this.editedItem.company.value);
            }

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            /* console.log('********** formData ********')
            console.log(formData) */

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.viewPost(result.data.term)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                console.log('********** error?.response ********')
                console.log(error?.response)

                if (error?.response?.status === 422) {
                    const errors = error.response?.data?.errors;

                    if (errors) {
                        if (errors.order) {
                            this.editedItem.order.error = errors.order[0];
                        }
                        if (errors.body) {
                            this.editedItem.body.error = errors.body[0];
                        }
                        if (errors.title) {
                            this.editedItem.title.error = errors.title[0];
                        }
                        if (errors.title) {
                            this.editedItem.term_category_id.error = errors.title[0];
                        }
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        viewPost(item) {
            // this.item = item;
            this.isOnlyView = true;

            this.editedItem = this.getEditedItem(item);

            /* console.log('this.editedItem **********')
            console.log(this.editedItem) */
        },
        restoreBody() {
            try {
                this.editedItem.body.value = this.item.body
            } catch (e) {

            }
        },
        async fetchCompanies() {
            try {
                const result = await CompaniesAPIService.getCompanies({signal: ''});

                this.companies = result.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        addTooltipToEditor() {
            const toolbarTooltips = {
                font: 'Select a font',
                size: 'Select a font size',
                header: 'Select the text style',
                bold: 'Bold',
                italic: 'Italic',
                underline: 'Underline',
                strike: 'Strikethrough',
                color: 'Select a text color',
                background: 'Select a background color',
                script: {
                    sub: 'Subscript',
                    super: 'Superscript'
                },
                list: {
                    ordered: 'Numbered list',
                    bullet: 'Bulleted list',
                    check: 'Checked list'
                },
                indent: {
                    '-1': 'Decrease indent',
                    '+1': 'Increase indent'
                },
                direction: {
                    rtl: 'Text direction (right to left | left to right)',
                    ltr: 'Text direction (left ro right | right to left)'
                },
                // align: 'Text alignment',
                align: {
                    default: 'Text alignment left',
                    center: 'Text alignment center',
                    justify: 'Text alignment justify',
                    right: 'Text alignment right'
                },
                blockquote: 'Blockquote',
                'code-block': 'Code block',
                link: 'Insert a link',
                image: 'Insert an image',
                video: 'Insert an video',
                formula: 'Insert a formula',
                clean: 'Clear format',
                'add-table': 'Add a new table',
                'table-row': 'Add a row to the selected table',
                'table-column': 'Add a column to the selected table',
                'remove-table': 'Remove selected table',
                help: 'Show help'
            };

            const lang = this.$store.getters['languages/getLang'];
            if (lang !== 'en') {
                Object.keys(toolbarTooltips).map(key => {
                    let value;
                    if (typeof toolbarTooltips[key] === 'string') {
                        value = 'editorActions.' + key;
                        toolbarTooltips[key] = this.$t(value);
                    } else {
                        Object.keys(toolbarTooltips[key]).map(subKey => {
                            value = 'editorActions.' + key + '.' + subKey;
                            toolbarTooltips[key][subKey] = this.$t(value);
                        });
                    }
                });
            }

            const showTooltip = (which, el) => {
                let tool;
                if (which === 'button') {
                    tool = el.className.replace('ql-', '');
                } else if (which === 'span') {
                    tool = el.className.replace('ql-', '');
                    tool = tool.substr(0, tool.indexOf(' '));
                }
                if (tool) {
                    // if element has value attribute.. handling is different
                    // buttons without value
                    if (el.value === '') {
                        if (toolbarTooltips[tool]) {
                            if (toolbarTooltips[tool].default) {
                                el.setAttribute('title', toolbarTooltips[tool].default);
                            } else { el.setAttribute('title', toolbarTooltips[tool]); }
                        }
                    } else if (typeof el.value !== 'undefined') { // buttons with value
                        if (toolbarTooltips[tool][el.value]) { el.setAttribute('title', toolbarTooltips[tool][el.value]); }
                    } else { el.setAttribute('title', toolbarTooltips[tool]); }// default
                }
            };

            const toolbarElement = document.querySelector('.ql-toolbar');
            if (toolbarElement) {
                const matchesButtons = toolbarElement.querySelectorAll('button');
                for (const el of matchesButtons) {
                    showTooltip('button', el);
                }
                // for submenus inside
                const matchesSpans = toolbarElement.querySelectorAll('.ql-toolbar > span > span');
                for (const el of matchesSpans) {
                    showTooltip('span', el);
                }
            }
        },
        editorCodeChanged(element) {
            this.editedItem.body.value = element;
        }
    }
}
</script>

<style >
/*iframe.ql-video {
  width: 500px !important;
  height: 500px !important;
}*/

.quill-editor iframe {
  pointer-events: none;
}

</style>
